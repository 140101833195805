import Vue from 'vue'
import { ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import { Dictionary } from 'vue-router/types/router'
import { reject } from 'lodash'
import { integer } from 'vuelidate/lib/validators'

interface MerchantModerationFormState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  serverErrors: any;

  object: any;
  form: any;
}

const $state: MerchantModerationFormState = {
  loading: false,
  loaded: false,
  fatalError: false,
  serverErrors: null,

  object: null,
  form: null,
}


const initState = cloneDeep($state)


const EMPTY_OBJECT = {
  id: null,

  brand_id: null,
  category_id: null,
  chief_name: null,
  chief_phone: null,
  city_id: null,
  franchise_contract_upload_id: null,
  inn: null,
  inn_upload_id: null,
  kpp: null,
  kpp_upload_id: null,
  legal_address: null,
  logo_upload_id: null,
  name: null,
  parent_id: null,
  trade_type: null,
  contacts: null,
  item_sum: 0,
  accural_start: 'auto',
  allow_accural_auto_start: true,
  allow_accural_manual_start: true,
  type: '',
  feedback_merchant: false,
  mark_merchant: false,
  feedback_product: false,
  mark_product: false,
  can_request_product_feedback: true,
  can_request_receipt_feedback: true,

  requisites_ogrn: null,
  requisites_ogrn_date: null,
  requisites_okpo: null,
  requisites_payment_account: null,
  requisites_correspondent_account: null,
  requisites_bik: null,
  requisites_bank_name: null,
  requisites_actual_address: null,
  requisites_tax_type_id: null,

  chief_phone_confirmed: false,
  chief_phone_confirmation_allowed: false,
  chief_phone_confirmation_attempts: 3,
  chief_phone_confirmation_rejected: false,
  chief_phone_confirmation_token: null,
  chief_phone_confirmation_code: null,
}

const AVAILABLE_FIELDS = [
  'id', 'brand_id', 'category_id', 'chief_name', 'chief_phone', 'city_id', 'franchise_contract_upload_id',
  'inn', 'inn_upload_id', 'kpp', 'kpp_upload_id', 'legal_address', 'logo_upload_id', 'name',
  'parent_id', 'trade_type', 'contacts', 'item_sum', 'accural_start', 'allow_accural_auto_start',
  'allow_accural_manual_start', 'feedback_merchant', 'mark_merchant', 'feedback_product',
  'mark_product', 'type', 'can_request_product_feedback', 'can_request_receipt_feedback',
  'requisites_ogrn', 'requisites_ogrn_date', 'requisites_okpo', 'requisites_payment_account',
  'requisites_correspondent_account', 'requisites_bik', 'requisites_bank_name',
  'requisites_actual_address', 'requisites_tax_type_id',

  'chief_phone_confirmed', 'chief_phone_confirmation_allowed', 'chief_phone_confirmation_attempts',
  'chief_phone_confirmation_rejected', 'chief_phone_confirmation_token', 'chief_phone_confirmation_code',
]

function check_phone(phone: string) {
  return phone.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)
}

function transform_phone(phone: string) {
  if (phone === null) {
    return phone
  }

  phone = phone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '')
  if ((phone.length > 5) && check_phone(phone)) {
    if (phone.charAt(0) === '+') {
      phone = '' + (Number(phone[1]) + 1) + phone.substring(2)
    }
  }

  return phone
}

function strcmp(a: string, b: string) {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

export const $actions: ActionTree<MerchantModerationFormState, RootState> = {

  initState({ commit, state }, { id }) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      vm.$http.get(`/admins/rest/v1/merchants/${id}/moderation`)
        .then((response: AxiosResponse) => {
          const form_data: any = {}

          const content_list = []

          content_list.push(
            {
              index: 0,
              original: true,
              label: 'Запись в нашей базе данных',
              object: response.data.merchant,
            },
          )

          let previous: any = response.data.merchant

          for (const m of response.data.unmoderated) {
            const dt = new Date(Date.parse(m.created_at))

            const dparts = dt.toISOString().substring(0, 10).split('-')
            const date_str = dparts[2] + '.' + dparts[1] + '.' + dparts[0]

            const time_str = dt.getHours().toString() + ':' + dt.getMinutes().toString()

            const datetime_label = date_str + ', ' + time_str

            const chg = []

            for (const mk of Object.keys(m)) {
              if (previous[mk] !== m[mk]) {
                chg.push(mk)
              }
            }

            previous = m

            content_list.push(
              {
                index: content_list.length,
                original: false,
                label: datetime_label,
                object: m,
                changed_fields: chg,
              },
            )
          }

          form_data.merchants = content_list

          commit('SET_FORM_DATA', {
            object: form_data,
          })
          commit('LOADED_SUCCESS')

          resolve(state.object)
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },

  prepareNew({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('GENERATE_FORM_DATA')
      commit('LOADED_SUCCESS')
      resolve(null)
    })
  },

  approve({ commit, state }, { id }) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      vm.$http.post(`/admins/rest/v1/merchants/${id}/moderation/approve`)
        .then((response: AxiosResponse) => {
          resolve(null)
        })
        .catch((error: AxiosError) => {
          console.error('Fatal error', error.response?.data)
          reject(error.response?.data)
        })
    })
  },

  discard({ commit, state }, { id }) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      vm.$http.post(`/admins/rest/v1/merchants/${id}/moderation/discard`)
        .then((response: AxiosResponse) => {
          resolve(null)
        })
        .catch((error: AxiosError) => {
          console.error('Fatal error', error.response?.data)
          reject(error.response?.data)
        })
    })
  },
}

export const $mutations: MutationTree<MerchantModerationFormState> = {
  RESET_STATE: (state) => {
    for (const key in initState) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },

  SET_FORM_DATA(state, { object }) {
    state.object = object

    const formData = cloneDeep(state.object)

    state.form = {
      ...formData,
    }

    state.form.requisites_actual_address_tooltip_value = {}
  },

  GENERATE_FORM_DATA(state) {
    state.form = {
      ...cloneDeep(EMPTY_OBJECT),
    }
  },
}

const $getters = {}

export const STORE_KEY = '$_merchant_moderation_form'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
};

