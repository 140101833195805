

















































































































































































































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, sameAs } from 'vuelidate/lib/validators'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'
import ComboboxSelectedObserver from '@/components/Controls/ComboboxSelectedObserver.vue'
import OutletList from '@/components/OutletList/OutletList.vue'

@Component({
  mixins: [validationMixin],
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'fatalError', 'serverErrors',
      'form', 'formDirty',
    ]),
  },
  validations() {
    const rules: any = {
      id: {},

      merchants: {},
    }

    return {
      form: rules,
    }
  },
  components: {
    TitleBar,
    ComboboxSelectedObserver,
    OutletList,
  },
})
export default class MerchantObserve extends Vue {
  public organisation_types: any = {
    ip: { name: 'ИП' },
    ooo: { name: 'ООО' },
  }

  public merchants_types: any = {
    producer: { name: 'Производитель' },
    merchant: { name: 'Магазин' },
    producer_merchant: { name: 'Производитель-Магазин' },
  }

  get isNew() {
    return !Number.isInteger(Number(this.$route.params.id))
  }

  get titleStack() {
    return [
      'Модерация', 'Список изменений информации об организации',
    ]
  }

  get active_tab() {
    if ('active_tab' in this.$route.params) {
      return this.$route.params.active_tab
    }

    return 'main'
  }

  /*
   *  2022-04-07  -->  07.04.2022
   */
  public format_date(date: string) {
    const parts = date.split('-')
    return parts[2] + '.' + parts[1] + '.' + parts[0]
  }

  public getContact(object: any, type: string) {
    // @ts-ignore
    if (!object.contacts) {
      return {
        value: '',
        is_public: true,
      }
    }
    // @ts-ignore
    for (const contact of object.contacts) {
      if (contact.type === type) {
        return {
          value: contact.value,
          is_public: contact.is_public,
        }
      }
    }
    return {
      value: '',
      is_public: true,
    }
  }


  public ApproveButtonClick(record_id: number) {
    this.$store.dispatch(`${STORE_KEY}/approve`, {
      id: record_id,
    }).then(
      (result: any) => {
        this.$buefy.snackbar.open(`Изменения приняты`)

        this.$store.dispatch(`${STORE_KEY}/initState`, {
          id: this.$route.params.id,
        }).then(
          (v: any) => {
            // @ts-ignore
            if (this.form.merchants.length <= 1) {
              this.$router.push({ name: 'moderation' })
            }
          },
        )
      },
    )
  }

  public DiscardButtonClick(record_id: number) {
    this.$store.dispatch(`${STORE_KEY}/discard`, {
      id: record_id,
    }).then(
      (result: any) => {
        this.$buefy.snackbar.open(`Запись отклонена`)

        this.$store.dispatch(`${STORE_KEY}/initState`, {
          id: this.$route.params.id,
        }).then(
          (v: any) => {
            // @ts-ignore
            if (this.form.merchants.length <= 1) {
              this.$router.push({ name: 'moderation' })
            }
          },
        )
      },
    )
  }

  public check(changed_info: string[], field: string) {
    if (Array.isArray(changed_info)) {
      return changed_info.includes(field)
    }

    return false
  }

  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.$store.dispatch(`${STORE_KEY}/initState`, {
      id: this.$route.params.id,
    })
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }
}
